import http from 'services/http';
import { BreadcrumbsType, MetaDataType } from 'services/common';
import { AxiosResponse } from 'axios';

export interface CatergoryType {
  id: number;
  name?: string;
  slug: string;
  colorCode: string;
  translations: Array<{
    locale: string;
    slug: string;
  }>;
  metaData?: MetaDataType;
}

export interface GuidesCatergoryPageType {
  data: CatergoryType;
  breadcrumbs: BreadcrumbsType;
  guidePage: {
    slug: string;
  };
}

export interface CategoriesResponse {
  data: Array<CatergoryType>;
  success: boolean;
  message: string;
}

export interface GuideType {
  id: number;
  thumbnail: string;
  title: string;
  slug: string;
  publishedAt: string;
  description: string;
  content: string;
  categories: Array<CatergoryType>;
  translations: Array<{ locale: string; slug: string | null }>;
}

export interface GuideDetailResponse {
  data: { data: GuideType; metaData: MetaDataType; breadcrumbs: BreadcrumbsType };
  message: string;
  success: boolean;
}

export interface GuidesResponse {
  data: Array<GuideType>;
  message: string;
  success: boolean;
  loaded: boolean;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
}

export interface GuidesPageType {
  title: string;
  pageMetaData: {
    newsSectionNews0: {
      heading: string;
      seeMore: string;
    };
  };
}

type FetchGuidesRequest = {
  page?: number;
  limit?: number;
  is_home?: boolean;
  show_in_smart_ac_page?: boolean;
  show_in_error_code_page?: boolean;
  show_in_faq_page?: boolean;
};

export const getCategoriesGuides = async (): Promise<CategoriesResponse> => {
  const { data } = await http.get('/guide-categories');
  return data;
};

export const getGuidesCategoryDetail = async (slugCategory: string) => {
  const { data } = await http.get<AxiosResponse<GuidesCatergoryPageType>>(`/guide-categories/slug/${slugCategory}`);
  return data;
};

export const getGuidesList = async (params: FetchGuidesRequest): Promise<GuidesResponse> => {
  const { data } = await http.get('/guides', { params });
  return data;
};

export const getGuidesByCategory = async (slug: string): Promise<GuidesResponse> => {
  const { data } = await http.get(`/guides/category-slug/${slug}`);
  return data;
};

export const getGuidesDetail = async (slug: string): Promise<GuideDetailResponse> => {
  const { data } = await http.get(`/guides/slug/${slug}`);
  return data;
};

export const getGuidesToPreview = async (code: string): Promise<GuideDetailResponse> => {
  const { data } = await http.get(`/guides/preview/${code}`);
  return data;
};
